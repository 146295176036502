import { render, staticRenderFns } from "./ServicePage.vue?vue&type=template&id=9e688c5e&scoped=true"
import script from "./ServicePage.vue?vue&type=script&lang=js"
export * from "./ServicePage.vue?vue&type=script&lang=js"
import style0 from "./ServicePage.vue?vue&type=style&index=0&id=9e688c5e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e688c5e",
  null
  
)

export default component.exports